import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { renderAddress } from '@helpers/functions'
// components
import { Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const Address = ({ isForm, address, setAddress, slashPushFromTop }) => {
    const { t } = useTranslation()

    slashPushFromTop = slashPushFromTop || '0'

    return (
        <>
            {!isForm ? (
                renderAddress(address)
            ) : (
                <>
                    <Form.Group>
                        <SuperField
                            as="input"
                            width="9"
                            required
                            label={t('address_street')}
                            placeholder={t('address_street')}
                            value={address.street}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    street: value,
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            width="3"
                            label={t('address_number')}
                            placeholder={t('address_number')}
                            value={address.number}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    number: value,
                                }))
                            }
                        />

                        <SuperField
                            as="input"
                            width="4"
                            label={t('orientation_number')}
                            placeholder={t('orientation_number')}
                            value={address.orientation_number}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    orientation_number: value,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <SuperField
                            as="input"
                            width="4"
                            label={t('postcode')}
                            placeholder={t('postcode')}
                            value={address.postcode}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    postcode: value,
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            width="8"
                            label={t('city')}
                            placeholder={t('city')}
                            value={address.city}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    city: value,
                                }))
                            }
                        />
                        <SuperField
                            search
                            style={{ marginTop: '0rem' }}
                            as="choice"
                            width="5"
                            label={t('country')}
                            type="countries"
                            value={address.country}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    country: value,
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            value={address.state}
                            label={t('address_state')}
                            placeholder={t('address_state')}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    state: value,
                                }))
                            }
                        />
                        <SuperField
                            as="input"
                            label={t('additional_information')}
                            placeholder={t('additional_information')}
                            value={address.address_line}
                            onChange={(e, { value }) =>
                                setAddress((prev) => ({
                                    ...prev,
                                    address_line: value,
                                }))
                            }
                        />
                    </Form.Group>
                </>
            )}
        </>
    )
}

export default Address
