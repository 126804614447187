import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Button } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const ContractSignForm = ({ record, setData, onClose }) => {
    const { t } = useTranslation()
    const [date, setDate] = useState(record?.date_of_signature || '')
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)

        let status = 6 // signed by default
        let signed_date = new Date(date)
        let valid_from = new Date(record.valid_from)
        let current_date = new Date()

        if (signed_date < valid_from && current_date <= valid_from) {
            // The contract is signed, but it hasn't come into effect yet
            status = 6 // Status remains as signed
        } else if (signed_date >= valid_from) {
            // The contract is auto-active because the signature date is after valid_from
            status = 1 // Example status for active
        } else if (current_date > valid_from) {
            // The valid_from date has passed, the contract should now be active
            status = 1 // Example status for active after valid_from
        }

        const request = await requests.patch(API.CONTRACTS + record.id + '/', {
            date_of_signature: date,
            status: status,
        })

        if (request.status === 200) {
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === record.id) {
                        item = request.response
                    }
                    return item
                })
            )
            onClose()
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="datepicker"
                closable
                required
                label={t('date_of_signature')}
                value={date}
                onChange={(e, { value }) => setDate(value)}
            />

            <Button fluid primary loading={isProcessing} content={t('save')} disabled={isProcessing || date === ''} />
        </Form>
    )
}

export default ContractSignForm
