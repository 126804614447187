import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
//components
import SuperField from '@components/forms/SuperField'
import CustomFormDivider from '@components/general/CustomFormDivider'
import { Form, Divider } from 'semantic-ui-react'

const ContractFilters = ({ filters, setFilters }) => {
    const { t } = useTranslation()

    const [invalidDateRangeValidUntil, setInvalidDateRangeValidUntil] = useState(false)
    const [invalidDateRangeValidFrom, setInvalidDateRangeValidFrom] = useState(false)
    const [invalidDateRangeTrialPeriod, setInvalidDateRangeTrialPeriod] = useState(false)
    const [invalidDateRangeExpectedEnd, setInvalidDateRangeExpectedEnd] = useState(false)

    // Validate Form
    useEffect(() => {
        if (filters.valid_until_after !== '' && filters.valid_until_before !== '') {
            if (moment(filters.valid_until_before).isAfter(filters.valid_until_after)) {
                setInvalidDateRangeValidUntil(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeValidUntil(false)
            }
        } else {
            setInvalidDateRangeValidUntil(false)
        }

        if (filters.valid_from_before !== '' && filters.valid_from_after !== '') {
            if (moment(filters.valid_from_after).isAfter(filters.valid_from_before)) {
                setInvalidDateRangeValidFrom(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeValidFrom(false)
            }
        } else {
            setInvalidDateRangeValidFrom(false)
        }

        if (filters.trial_period_before !== '' && filters.trial_period_after !== '') {
            if (moment(filters.trial_period_after).isAfter(filters.trial_period_before)) {
                setInvalidDateRangeTrialPeriod(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeTrialPeriod(false)
            }
        } else {
            setInvalidDateRangeTrialPeriod(false)
        }

        if (filters.expected_end_date_before !== '' && filters.expected_end_date_after !== '') {
            if (moment(filters.expected_end_date_after).isAfter(filters.expected_end_date_before)) {
                setInvalidDateRangeExpectedEnd(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRangeExpectedEnd(false)
            }
        } else {
            setInvalidDateRangeExpectedEnd(false)
        }

        // eslint-disable-next-line
    }, [filters])

    return (
        <div>
            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    label={t('employee')}
                    endpoint={API.EMPLOYEES}
                    additionalFilters="&only_basic_info=true&is_active=true"
                    text={(item) => item?.fullname_with_titles}
                    value={filters.employee}
                    onChange={(e, { value }) => setFilters({ ...filters, employee: value })}
                />
                <SuperField
                    as="choice-select"
                    search
                    label={t('employer')}
                    endpoint={API.BUSINESS_DETAIL}
                    additionalFilters="&query={id, name}&is_employer=true"
                    text={(item) => item?.name}
                    value={filters.employer}
                    onChange={(e, { value }) => setFilters({ ...filters, employer: value })}
                />
            </Form.Group>

            <SuperField
                as="choice-select"
                search
                text="name"
                value={filters.unit}
                label={t('department')}
                onChange={(e, { value }) => setFilters({ ...filters, unit: value })}
                endpoint={API.UNITS + '?query={id, name}&only_basic_info=true&only_from_active_company=true'} //&responsible_persons=" + profile_id}
            />

            <SuperField
                as="choice-select"
                multiple
                search
                label={t('work_position')}
                value={filters.positions}
                endpoint={API.JOB_POSITIONS}
                additionalFilters="&query={id, title, job_contract{work_level}, bonuses{id, title}}"
                text={(item) => item?.title}
                onChange={(e, { value }) => setFilters({ ...filters, positions: value })}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    text="title"
                    label={t('work_position_group')}
                    value={filters.position_group}
                    endpoint={API.POSITIONS_GROUPS}
                    additionalFilters="&query={id, title}"
                    onChange={(e, { value }) => setFilters({ ...filters, position_group: value })}
                />
                <SuperField
                    as="choice-select"
                    text="title"
                    value={filters.type}
                    label={t('contract_type')}
                    endpoint={API.CONTRACTS_TYPES}
                    onChange={(e, { value }) => setFilters({ ...filters, type: value })}
                />
            </Form.Group>

            <SuperField
                as="checkbox"
                label={t('indefinite_period_only')}
                checked={filters.is_valid_until_none}
                onChange={() =>
                    setFilters({ ...filters, is_valid_until_none: filters.is_valid_until_none === '' ? true : '' })
                }
            />

            <SuperField
                as="checkbox"
                label={t('show_only_employed_by_agency')}
                checked={filters.is_employed_by_agencies}
                onChange={() =>
                    setFilters({
                        ...filters,
                        is_employed_by_agencies: filters.is_employed_by_agencies === '' ? true : '',
                    })
                }
            />

            <SuperField
                as="checkbox"
                label={t('not_assigned_unit')}
                checked={filters.not_assigned_unit === true}
                onChange={() =>
                    setFilters((prev) => ({
                        ...prev,
                        not_assigned_unit: filters.not_assigned_unit === true ? '' : true,
                    }))
                }
            />

            <h4>{t('registration_slash_unregistration')}</h4>

            <Form.Group widths="equal">
                <SuperField
                    as="radio"
                    label={t('has_insurance_registration')}
                    checked={filters.has_insurance_registration === true}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            has_insurance_registration: filters.has_insurance_registration === true ? '' : true,
                        }))
                    }
                />
                <SuperField
                    as="radio"
                    label={t('has_not_insurance_registration')}
                    checked={filters.has_insurance_registration === false}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            has_insurance_registration: filters.has_insurance_registration === false ? '' : false,
                        }))
                    }
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="radio"
                    label={t('has_social_registration')}
                    checked={filters.has_social_registration === true}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            has_social_registration: filters.has_social_registration === true ? '' : true,
                        }))
                    }
                />

                <SuperField
                    as="radio"
                    label={t('has_not_social_registration')}
                    checked={filters.has_social_registration === false}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            has_social_registration: filters.has_social_registration === false ? '' : false,
                        }))
                    }
                />
            </Form.Group>

            <Divider />

            <Form.Group widths="equal">
                <SuperField
                    as="radio"
                    label={t('is_social_unregistered')}
                    checked={filters.is_social_unregistered === true}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            is_social_unregistered: filters.is_social_unregistered === true ? '' : true,
                        }))
                    }
                />

                <SuperField
                    as="radio"
                    label={t('is_not_social_unregistered')}
                    checked={filters.is_social_unregistered === false}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            is_social_unregistered: filters.is_social_unregistered === false ? '' : false,
                        }))
                    }
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="radio"
                    label={t('is_insurance_unregistered')}
                    checked={filters.is_insurance_unregistered === true}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            is_insurance_unregistered: filters.is_insurance_unregistered === true ? '' : true,
                        }))
                    }
                />
                <SuperField
                    as="radio"
                    label={t('is_not_insurance_unregistered')}
                    checked={filters.is_insurance_unregistered === false}
                    onChange={() =>
                        setFilters((prev) => ({
                            ...prev,
                            is_insurance_unregistered: filters.is_insurance_unregistered === false ? '' : false,
                        }))
                    }
                />
            </Form.Group>

            <CustomFormDivider
                header={t('valid_from_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('date_from')}
                            value={filters.valid_from_after}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_from_after: value })}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('date_to')}
                            error={invalidDateRangeValidFrom}
                            value={filters.valid_from_before}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_from_before: value })}
                        />
                    </Form.Group>
                }
            />
            <CustomFormDivider
                header={t('valid_until_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('date_from')}
                            value={filters.valid_until_after}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_until_after: value })}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('date_to')}
                            error={invalidDateRangeValidUntil}
                            value={filters.valid_until_before}
                            onChange={(e, { value }) => setFilters({ ...filters, valid_until_before: value })}
                        />
                    </Form.Group>
                }
            />
            <CustomFormDivider
                header={t('trial_period_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('date_from')}
                            value={filters.trial_period_after}
                            onChange={(e, { value }) => setFilters({ ...filters, trial_period_after: value })}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('date_to')}
                            error={invalidDateRangeTrialPeriod}
                            value={filters.trial_period_before}
                            onChange={(e, { value }) => setFilters({ ...filters, trial_period_before: value })}
                        />
                    </Form.Group>
                }
            />
            <CustomFormDivider
                header={t('expected_end_date_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            label={t('date_from')}
                            value={filters.expected_end_date_after}
                            onChange={(e, { value }) => setFilters({ ...filters, expected_end_date_after: value })}
                        />
                        <SuperField
                            as="datepicker"
                            label={t('date_to')}
                            error={invalidDateRangeExpectedEnd}
                            value={filters.expected_end_date_before}
                            onChange={(e, { value }) => setFilters({ ...filters, expected_end_date_before: value })}
                        />
                    </Form.Group>
                }
            />
        </div>
    )
}

export default ContractFilters
