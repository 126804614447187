import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermission } from '@helpers/hooks'
// components
import { Form, Divider, Header } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import moment from 'moment'

const AdvanceForm = ({ isAdvance, record, setData, setTotal, onClose }) => {
    const { t } = useTranslation()

    // get me current day and first day in month from previous month
    const currentDay = moment()
    const previousMonth = currentDay.clone().subtract(1, 'months')
    const contract_date_to = currentDay.format('YYYY-MM-DD')
    const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

    const profile = useSelector((state) => state.user.profile)
    const canViewAll = useHasPermission(
        isAdvance ? 'payrolls.c_view_all_advance_payments' : 'payrolls.c_view_all_deduction_payments'
    )
    const canViewOnlyResponsibleFor = useHasPermission(
        isAdvance ? 'payrolls.c_view_user_advance_payments' : 'payrolls.c_view_user_deduction_payments'
    )
    const canApprove = useHasPermission('approvals.c_manage_all_approvals')
    const months = [
        { key: 1, value: '01', text: t('january') },
        { key: 2, value: '02', text: t('february') },
        { key: 3, value: '03', text: t('march') },
        { key: 4, value: '04', text: t('april') },
        { key: 5, value: '05', text: t('may') },
        { key: 6, value: '06', text: t('june') },
        { key: 7, value: '07', text: t('july') },
        { key: 8, value: '08', text: t('august') },
        { key: 9, value: '09', text: t('september') },
        { key: 10, value: '10', text: t('october') },
        { key: 11, value: '11', text: t('november') },
        { key: 12, value: '12', text: t('december') },
    ]

    const [isProcessing, setIsProcessing] = useState(false)
    const [loadingContracts, setLoadingContracts] = useState(false)
    const [contracts, setContracts] = useState([])
    const [errors, setErrors] = useState(null)
    const [advance, setAdvance] = useState({
        date_of_payment: record?.date_of_payment || null,
        billing_period: record?.billing_period || '',
        billing_month: record?.billing_month || moment().format('MM'),
        billing_year: record?.billing_year || moment().format('YYYY'),
        amount: record?.amount || '',
        currency: record?.currency || 'EUR',
        note: record?.note || '',
        contract: record?.contract?.id || '',
    })

    const [loadingApprovers, setLoadingApprovers] = useState(false)
    const [approvers, setApprovers] = useState([])
    const [responsiblePerson, setResponsiblePerson] = useState('')

    async function fetchApprovers() {
        setLoadingApprovers(true)
        const request = await requests.get(API.EMPLOYEES + '?only_basic_info=true&is_active=true&has_user=true')

        if (request.status === 200) {
            // demanova cheat:
            const isDemanovaInstance =
                window.location.hostname === 'www.demanovarezort.pulsawork.com' ||
                window.location.hostname === 'demanovarezort.pulsawork.com'
            if (isDemanovaInstance) {
                // find julia (profile_id = 176)
                const julia = request.response.find((item) => item.id === 176)
                if (julia) {
                    setApprovers([{ key: julia.id, value: julia.id, text: julia.fullname_with_titles }])
                    setResponsiblePerson(julia.id)
                }
            } else {
                // other instances
                const approversList = request.response
                    .filter((item) => item.id !== profile?.id)
                    .map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: item.fullname_with_titles,
                    }))
                setApprovers(approversList)
                if (approversList.length === 1) setResponsiblePerson(approversList[0].value)
            }
        }
        setLoadingApprovers(false)
    }

    useEffect(() => {
        async function fetchContracts() {
            setLoadingContracts(true)
            const request = await requests.get(
                API.CONTRACTS +
                    `?query={id, employee{id, fullname}}&only_basic_info=true&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}` +
                    (!canViewAll && canViewOnlyResponsibleFor ? '&responsible_person=' + profile.id : '')
            )

            if (request.status === 200) {
                const results = request?.response?.results || []
                if (!canViewAll && canViewOnlyResponsibleFor) {
                    setContracts(results.filter((item) => item.employee.id !== profile.id))
                } else {
                    setContracts(results)
                }
            }
            setLoadingContracts(false)
        }

        fetchApprovers()
        fetchContracts()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const lastDayOfMonth = moment(advance.billing_year + '-' + advance.billing_month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        setAdvance((prev) => ({ ...prev, billing_period: lastDayOfMonth }))
    }, [advance.billing_month, advance.billing_year])

    const onSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)

        let request = null
        if (record?.id) {
            if (isAdvance) request = await requests.put(API.PAYROLLS + 'advance_payments/' + record.id + '/', advance)
            else request = await requests.put(API.PAYROLLS + 'deduction_payments/' + record.id + '/', advance)
        } else {
            const data = advance
            if (!canApprove) data['responsible_person'] = responsiblePerson
            if (isAdvance) request = await requests.post(API.PAYROLLS + 'advance_payments/', data)
            else request = await requests.post(API.PAYROLLS + 'deduction_payments/', data)
        }

        if (request?.status === 400) setErrors(request.response)
        if (request.status === 201) {
            setData((prevState) => [request.response, ...prevState])
            setTotal((prev) => prev + 1)
            if (request.response.is_approved === false) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: isAdvance
                        ? t('advance_assigned_require_approval')
                        : t('deduction_assigned_require_approval'),
                    animation: 'pulse',
                    time: 5000,
                })
            } else {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: isAdvance ? t('advance_assigned_and_approved') : t('deduction_assigned_and_approved'),
                    animation: 'pulse',
                    time: 5000,
                })
            }
            onClose()
        } else if (request?.status === 200) {
            setData((prev) =>
                prev.map((item) => {
                    if (item.id === record.id) return request.response

                    return item
                })
            )
            onClose()
        }

        setIsProcessing(false)
    }

    const isFormValid = () => {
        if (advance.contract === '') return false
        if (advance.billing_month === '') return false
        if (advance.billing_year === '') return false
        if (advance.amount === '' || advance.amount <= 0 || isNaN(advance.amount)) return false
        if (advance.currency === '') return false

        // if you dont have permission for approval then require to select responsible person for approval
        if (!canApprove && responsiblePerson === '' && record?.id !== undefined) return false

        return true
    }

    return (
        <Form onSubmit={onSubmit}>
            {record?.id && (
                <>
                    {isAdvance ? (
                        <Header as="h3" content={t('edit_advance')} />
                    ) : (
                        <Header as="h3" content={t('edit_deduction')} />
                    )}
                    <Divider />
                </>
            )}

            {record?.id === undefined && (
                <SuperField
                    search
                    required
                    as="choice"
                    label={t('employee')}
                    value={advance.contract}
                    loading={loadingContracts}
                    disabled={loadingContracts}
                    customOptions={contracts.map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: item.employee.fullname,
                    }))}
                    onChange={(e, { value }) => setAdvance({ ...advance, contract: value })}
                />
            )}

            <Form.Group widths={'equal'}>
                <SuperField
                    as="choice"
                    search
                    required
                    label={t('month')}
                    clearable={false}
                    customOptions={months}
                    value={
                        advance.billing_month.toString().length === 1
                            ? '0' + advance.billing_month
                            : advance.billing_month
                    }
                    onChange={(e, { value }) => setAdvance({ ...advance, billing_month: value })}
                />
                <SuperField
                    as="yearpicker"
                    required
                    label={t('year')}
                    value={advance.billing_year}
                    onChange={(e, { value }) => setAdvance({ ...advance, billing_year: value })}
                />
            </Form.Group>

            <Form.Group widths={'equal'}>
                <SuperField
                    as="input"
                    required
                    value={advance?.amount}
                    label={t('amount_sum')}
                    placeholder={t('amount_sum')}
                    error={errors?.amount?.[0] || false}
                    onChange={(e, { value }) => setAdvance({ ...advance, amount: value.replace(',', '.') })}
                />
                <SuperField
                    as="choice"
                    required
                    search
                    clearable={false}
                    type="currency_codes"
                    value={advance.currency}
                    label={t('currency')}
                    onChange={(e, { value }) => setAdvance((prev) => ({ ...prev, currency: value }))}
                />
            </Form.Group>

            {!canApprove && record?.id === undefined && (
                <SuperField
                    as="choice-select"
                    search
                    style={{ marginBottom: '0' }}
                    required={!canApprove}
                    value={responsiblePerson}
                    // text="fullname_with_titles"
                    exclude={profile ? [profile.id] : []}
                    label={t('responsible_person_for_approval')}
                    error={errors?.responsible_person?.[0] || false}
                    onChange={(e, { value }) => setResponsiblePerson(value)}
                    customOptions={approvers}
                    loading={loadingApprovers}
                    disabled={loadingApprovers}
                    // endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true&has_user=true'}
                />
            )}

            {canApprove && (
                <Form.Group widths={'equal'}>
                    <SuperField
                        as="datepicker"
                        label={t('date_of_payment')}
                        value={advance.date_of_payment}
                        onChange={(e, { value }) => setAdvance({ ...advance, date_of_payment: value })}
                    />
                </Form.Group>
            )}

            <SuperField
                as="textarea"
                label={t('note')}
                value={advance.note}
                onChange={(e, { value }) => setAdvance((prev) => ({ ...prev, note: value }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || !isFormValid()}
                    text={canApprove ? t('confirm') : t('request_approval')}
                />
            </Form.Field>
        </Form>
    )
}

export default AdvanceForm
